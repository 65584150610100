var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[(_vm.$route.name == 'salesChequesCreate')?_c('addHeader',{attrs:{"title":_vm.$t('salesCheques.form.addSalesPayment'),"description":_vm.$t('salesCheques.form.FromHereYouCanCreateaNewSalesPayment')}}):_vm._e(),(_vm.$route.name == 'salesChequesEdit')?_c('addHeader',{attrs:{"title":_vm.$t('salesCheques.form.SalesPaymentadjustment'),"description":_vm.$t('salesCheques.form.FromHereYouCanModifyTheSalesPaymentInformation')}}):_vm._e(),_c('div',{staticClass:"mb-4 row"},[(_vm.$route.name == 'salesChequesCreate')?_c('connectionInput',{attrs:{"title":_vm.$t('salesCheques.form.NumberingTheBatch'),"group":_vm.$t('salesCheques.form.Groups'),"disabled":_vm.$user.admin || _vm.$user.role.sales_cheques_edit_item
              ? false
              : true,"hasErorr":_vm.errors.invoice_group,"error":_vm.$t('salesCheques.form.ThisFieldIsRequired'),"values":_vm.$database.invoiceGroups},model:{value:(_vm.item.invoice_group),callback:function ($$v) {_vm.$set(_vm.item, "invoice_group", $$v)},expression:"item.invoice_group"}}):_vm._e(),(_vm.$route.name == 'salesChequesEdit')?_c('formInput',{attrs:{"title":_vm.$t('salesCheques.form.PaymentCode'),"disabled":true},model:{value:(_vm.item.code),callback:function ($$v) {_vm.$set(_vm.item, "code", $$v)},expression:"item.code"}}):_vm._e(),_c('subFileInput',{attrs:{"title":_vm.$t('invoices.form.invoiceFile'),"file":_vm.item.file,"path":'invoices',"hasErorr":_vm.errors.file,"error":_vm.$t('allerts.ChooseASuitableFile')},model:{value:(_vm.item.file),callback:function ($$v) {_vm.$set(_vm.item, "file", $$v)},expression:"item.file"}}),_c('formInput',{attrs:{"title":_vm.$t('salesCheques.checkNumber')},model:{value:(_vm.item.refrance),callback:function ($$v) {_vm.$set(_vm.item, "refrance", $$v)},expression:"item.refrance"}}),_c('formInput',{attrs:{"title":_vm.$t('purchaseCheques.form.checkHolder'),"type":'text',"maxlength":"255"},model:{value:(_vm.item.holder_name),callback:function ($$v) {_vm.$set(_vm.item, "holder_name", $$v)},expression:"item.holder_name"}}),_c('selectInput',{attrs:{"title":_vm.$t('salesCheques.form.TypeOfCheck'),"disabled":_vm.$route.name == 'salesChequesEdit' ? true : false,"values":[
            { name: _vm.$t('salesCheques.form.ClientCheck'), value: 0 },
            { name: _vm.$t('salesCheques.form.SupplierCheck'), value: 1 },
            { name: _vm.$t('salesCheques.form.PartnerCheck'), value: 2 } ]},model:{value:(_vm.item.client_type),callback:function ($$v) {_vm.$set(_vm.item, "client_type", $$v)},expression:"item.client_type"}}),(_vm.item.client_type == 0)?_c('searchGroupInput',{attrs:{"type":'customer',"values":_vm.$database.customers.filter(function (el) { return el.stat == 1; }),"inputs":_vm.$option.salesCheques_customer_data}}):_vm._e(),(_vm.item.client_type == 1)?_c('searchGroupInput',{attrs:{"type":'supplier',"values":_vm.$database.suppliers.filter(function (el) { return el.stat == 1; }),"inputs":_vm.$option.salesCheques_customer_data}}):_vm._e(),(_vm.item.client_type == 2)?_c('searchGroupInput',{attrs:{"type":'partner',"values":_vm.$database.partners.filter(function (el) { return el.stat == 1; }),"inputs":_vm.$option.salesCheques_customer_data}}):_vm._e(),_c('div',{staticClass:"form-group row",class:'col-md-6'},[_c('label',{class:'col-md-4'},[_vm._v(_vm._s(_vm.$t('salesCheques.form.ConstructionDate')))]),_c('div',{class:'col-md-7'},[_c('div',{staticClass:"icon-input w-100 to-left"},[_vm._m(0),_c('VueCtkDateTimePicker',{attrs:{"label":_vm.$t('salesCheques.form.ChooseTimeAndDate'),"button-now-translation":_vm.$t('salesCheques.form.Now'),"disabled":_vm.$user.admin ||
                  _vm.$user.role.sales_cheques_edit_item
                    ? false
                    : true,"format":"YYYY-MM-DD hh:mm a","color":"#631263","button-color":"#631263"},model:{value:(_vm.item.created_date),callback:function ($$v) {_vm.$set(_vm.item, "created_date", $$v)},expression:"item.created_date"}})],1)])]),_c('div',{staticClass:"form-group row",class:'col-md-6'},[_c('label',{class:'col-md-4'},[_vm._v(_vm._s(_vm.$t('salesCheques.form.PaymentDate')))]),_c('div',{class:'col-md-7'},[_c('div',{staticClass:"icon-input w-100 to-left"},[_vm._m(1),_c('VueCtkDateTimePicker',{attrs:{"label":_vm.$t('salesCheques.form.ChooseTimeAndDate'),"button-now-translation":_vm.$t('salesCheques.form.Now'),"format":"YYYY-MM-DD hh:mm a","color":"#631263","button-color":"#631263"},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}})],1)])]),(_vm.$site.projects_allow)?_c('searchGroupInput',{attrs:{"type":'project',"values":_vm.$database.projects,"inputs":[{ 'title':_vm.$t('salesCheques.form.Project'),'show': 'name'}]}}):_vm._e(),(_vm.$site.projects_allow)?_c('connectionInput',{attrs:{"disabled":_vm.item.project_id == 0,"title":_vm.$t('salesCheques.form.ProjectOutput'),"group":_vm.$t('salesCheques.form.ProjectOutputs'),"values":_vm.$database.projectItems.filter(function (el) { return el.project_id == _vm.item.project_id; })},model:{value:(_vm.item.project_item_id),callback:function ($$v) {_vm.$set(_vm.item, "project_item_id", $$v)},expression:"item.project_item_id"}}):_vm._e(),_c('dvider',{attrs:{"title":_vm.$t('salesCheques.form.AccountsInformation'),"description":_vm.$t('salesCheques.form.AccountInformationDetails')}}),_c('selectInput',{attrs:{"title":_vm.$t('salesCheques.form.checkStatus'),"values":[
            { name: _vm.$t('salesCheques.form.pending'), value: 0 },
            { name: _vm.$t('salesCheques.form.paid'), value: 1 },
            { name: _vm.$t('salesCheques.form.converted'), value: 2 },
            { name: _vm.$t('salesCheques.form.refused'), value: 3 }
          ]},model:{value:(_vm.item.stat),callback:function ($$v) {_vm.$set(_vm.item, "stat", $$v)},expression:"item.stat"}}),_c('searchGroupInput',{attrs:{"type":'safe',"values":_vm.$database.safes,"inputs":[{ 'show': 'name'}]}}),_c('monyInput',{attrs:{"title":_vm.$t('salesCheques.form.ReceivedAmount'),"hasErorr":_vm.errors.cost,"error":_vm.$t('allerts.theAmountReceivedMustBeGreaterThan')},model:{value:(_vm.item.cost),callback:function ($$v) {_vm.$set(_vm.item, "cost", $$v)},expression:"item.cost"}}),_c('monyInput',{attrs:{"title":_vm.$t('salesCheques.form.BankSees'),"disabled":_vm.$user.admin || _vm.$user.role.sales_cheques_edit_item
              ? false
              : true},model:{value:(_vm.item.bank_fees),callback:function ($$v) {_vm.$set(_vm.item, "bank_fees", $$v)},expression:"item.bank_fees"}}),_c('div',{staticClass:"form-group col-md-6 row"},[_c('label',{staticClass:"col-md-4"},[_vm._v(_vm._s(_vm.$t('salesCheques.form.PaymentFee')))]),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"d-flex mult-with-add"},[_c('multiselect',{attrs:{"options":_vm.$database.paymentFees,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":_vm.$t('salesCheques.form.PaymentFee'),"label":"name","track-by":"name","hide-selected":true,"preselect-first":true,"disabled":_vm.$user.admin ||
                  _vm.$user.role.sales_cheques_edit_item
                    ? false
                    : true},model:{value:(_vm.selectedPaymentFees),callback:function ($$v) {_vm.selectedPaymentFees=$$v},expression:"selectedPaymentFees"}}),_c('button',{staticClass:"btn btn-info",attrs:{"disabled":_vm.$user.admin ||
                  _vm.$user.role.sales_cheques_edit_item
                    ? false
                    : true},on:{"click":function($event){_vm.model = 'addPaymentFee'}}},[_c('i',{staticClass:"fa fa-plus"})])],1)])]),_c('connectionInput',{attrs:{"title":_vm.$t('salesCheques.form.PaymentMethod'),"group":_vm.$t('salesCheques.form.PaymentMethods'),"hasAdd":true,"open":'addPaymentMethod',"values":_vm.$database.paymentMethods,"value":_vm.item.payment_fee_id,"disabled":_vm.$user.admin || _vm.$user.role.sales_cheques_edit_item
              ? false
              : true},model:{value:(_vm.item.payment_method_id),callback:function ($$v) {_vm.$set(_vm.item, "payment_method_id", $$v)},expression:"item.payment_method_id"}}),_c('checkInput',{attrs:{"title":_vm.$t('salesCheques.form.BankTax'),"value":_vm.item.bank_tax,"disabled":_vm.$user.admin || _vm.$user.role.sales_cheques_edit_item
              ? false
              : true},model:{value:(_vm.item.bank_tax),callback:function ($$v) {_vm.$set(_vm.item, "bank_tax", $$v)},expression:"item.bank_tax"}}),(_vm.item.bank_tax == 1)?_c('monyInput',{attrs:{"title":_vm.$t('salesCheques.form.TaxAmount'),"disabled":_vm.$user.admin || _vm.$user.role.sales_cheques_edit_item
              ? false
              : true},model:{value:(_vm.item.bank_tax_amount),callback:function ($$v) {_vm.$set(_vm.item, "bank_tax_amount", $$v)},expression:"item.bank_tax_amount"}}):_vm._e(),_c('div',{staticClass:"col-6"}),(_vm.item.stat == 2)?_c('dvider',{attrs:{"title":_vm.$t('salesCheques.form.transferInformation'),"description":_vm.$t('salesCheques.form.CheckTransferInformation')}}):_vm._e(),(_vm.item.stat == 2)?_c('searchGroupInput',{attrs:{"type":'supplier',"values":_vm.$database.suppliers.filter(function (el) { return el.stat == 1; }),"model":'convert_supplier',"inputs":[
            { title: this.$t('companies.form.supplierName'), show: 'name' },
            { title: this.$t('companies.form.Balance'), show: 'balance', moany: true }
          ]}}):_vm._e(),_c('dvider',{attrs:{"title":_vm.$t('salesCheques.form.PaymentNotes'),"description":_vm.$t('salesCheques.form.PaymentNotesDetails')}}),_c('formTextarea',{attrs:{"title":_vm.$t('salesCheques.form.thatAbout')},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}}),_c('formTextarea',{attrs:{"title":_vm.$t('salesCheques.form.Note')},model:{value:(_vm.item.notes),callback:function ($$v) {_vm.$set(_vm.item, "notes", $$v)},expression:"item.notes"}}),_c('hr',{staticClass:"mt-5 mb-5 col-12"}),(_vm.$route.name == 'salesChequesCreate' && !_vm.$parent.stopEdit)?_c('a',{staticClass:"btn btn-block btn-primary",on:{"click":function($event){return _vm.saveItem()}}},[_vm._v(" "+_vm._s(_vm.$t('salesCheques.form.addSalesPayment'))+" ")]):_vm._e(),(_vm.$route.name == 'salesChequesEdit' && !_vm.$parent.stopEdit)?_c('a',{staticClass:"btn btn-block btn-primary",on:{"click":function($event){return _vm.saveItem()}}},[_vm._v(" "+_vm._s(_vm.$t('salesCheques.form.SalesPaymentadjustment'))+" ")]):_vm._e(),(_vm.$parent.stopEdit)?_c('loading'):_vm._e()],1)],1)]),(_vm.model == 'addPaymentMethod')?_c('addPaymentMethod'):_vm._e(),(_vm.model == 'addPaymentFee')?_c('addPaymentFee'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"far fa-calendar"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"far fa-calendar"})])}]

export { render, staticRenderFns }