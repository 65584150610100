<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('salesCheques.form.addSalesPayment')"
          :description="
            $t('salesCheques.form.FromHereYouCanCreateaNewSalesPayment')
          "
          v-if="$route.name == 'salesChequesCreate'"
        />
        <addHeader
          :title="$t('salesCheques.form.SalesPaymentadjustment')"
          :description="$t('salesCheques.form.FromHereYouCanModifyTheSalesPaymentInformation')"
          v-if="$route.name == 'salesChequesEdit'"
        />

        <div class="mb-4 row">
          <connectionInput
            :title="$t('salesCheques.form.NumberingTheBatch')"
            :group="$t('salesCheques.form.Groups')"
            v-model="item.invoice_group"
            :disabled="
              $user.admin || $user.role.sales_cheques_edit_item
                ? false
                : true
            "
            :hasErorr="errors.invoice_group"
            :error="$t('salesCheques.form.ThisFieldIsRequired')"
            :values="$database.invoiceGroups"
            v-if="$route.name == 'salesChequesCreate'"
          />
          <formInput
            :title="$t('salesCheques.form.PaymentCode')"
            v-model="item.code"
            :disabled="true"
            v-if="$route.name == 'salesChequesEdit'"
          />
          <subFileInput :title="$t('invoices.form.invoiceFile')"
            v-model="item.file"
            :file="item.file"
            :path="'invoices'"
            :hasErorr="errors.file"
            :error="$t('allerts.ChooseASuitableFile')"
          />
          <formInput
            :title="$t('salesCheques.checkNumber')"
            v-model="item.refrance"
          />
          <formInput
            :title="$t('purchaseCheques.form.checkHolder')"
            v-model="item.holder_name"
            :type="'text'"
            maxlength="255" 
          />
          <selectInput
            :title="$t('salesCheques.form.TypeOfCheck')"
            v-model="item.client_type"
            :disabled="$route.name == 'salesChequesEdit' ? true : false"
            :values="[
              { name: $t('salesCheques.form.ClientCheck'), value: 0 },
              { name: $t('salesCheques.form.SupplierCheck'), value: 1 },
              { name: $t('salesCheques.form.PartnerCheck'), value: 2 },
            ]"
          />

          <searchGroupInput
            v-if="item.client_type == 0"
            :type="'customer'"
            :values="$database.customers.filter((el) => el.stat == 1)"
            :inputs="$option.salesCheques_customer_data"
          />

          <searchGroupInput
            v-if="item.client_type == 1"
            :type="'supplier'"
            :values="$database.suppliers.filter((el) => el.stat == 1)"
            :inputs="$option.salesCheques_customer_data"
          />

          <searchGroupInput
            v-if="item.client_type == 2"
            :type="'partner'"
            :values="$database.partners.filter((el) => el.stat == 1)"
            :inputs="$option.salesCheques_customer_data"
          />
          
          
          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{$t('salesCheques.form.ConstructionDate')}}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('salesCheques.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('salesCheques.form.Now')"
                  v-model="item.created_date"
                  :disabled="
                    $user.admin ||
                    $user.role.sales_cheques_edit_item
                      ? false
                      : true
                  "
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{
              $t('salesCheques.form.PaymentDate')
            }}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('salesCheques.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('salesCheques.form.Now')"
                  v-model="item.date"
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>

          <searchGroupInput
          :type="'project'"
          :values="$database.projects"
          :inputs="[{ 'title':$t('salesCheques.form.Project'),'show': 'name'}]"
          v-if="$site.projects_allow"
        />


          <connectionInput
            :disabled="item.project_id == 0"
            :title="$t('salesCheques.form.ProjectOutput')"
            :group="$t('salesCheques.form.ProjectOutputs')"
            v-model="item.project_item_id"
            :values="$database.projectItems.filter(el => el.project_id == item.project_id)"
            v-if="$site.projects_allow"
          />

          <dvider
            :title="$t('salesCheques.form.AccountsInformation')"
            :description="$t('salesCheques.form.AccountInformationDetails')"
          />

        <selectInput
            :title="$t('salesCheques.form.checkStatus')"
            v-model="item.stat"
            :values="[
              { name: $t('salesCheques.form.pending'), value: 0 },
              { name: $t('salesCheques.form.paid'), value: 1 },
              { name: $t('salesCheques.form.converted'), value: 2 },
              { name: $t('salesCheques.form.refused'), value: 3 }
            ]"
          />

          <searchGroupInput
            :type="'safe'"
            :values="$database.safes"
            :inputs="[{ 'show': 'name'}]"
          />


          <monyInput
            :title="$t('salesCheques.form.ReceivedAmount')"
            v-model="item.cost"
            :hasErorr="errors.cost"
            :error="$t('allerts.theAmountReceivedMustBeGreaterThan')"
          />

          <monyInput
            :title="$t('salesCheques.form.BankSees')"
            v-model="item.bank_fees"
            :disabled="
              $user.admin || $user.role.sales_cheques_edit_item
                ? false
                : true
            "
          />

          <div class="form-group col-md-6 row">
            <label class="col-md-4">{{
              $t('salesCheques.form.PaymentFee')
            }}</label>
            <div class="col-md-7">
              <div class="d-flex mult-with-add">
                <multiselect
                  v-model="selectedPaymentFees"
                  :options="$database.paymentFees"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('salesCheques.form.PaymentFee')"
                  label="name"
                  track-by="name"
                  :hide-selected="true"
                  :preselect-first="true"
                  :disabled="
                    $user.admin ||
                    $user.role.sales_cheques_edit_item
                      ? false
                      : true
                  "
                ></multiselect>
                <button
                  class="btn btn-info"
                  @click="model = 'addPaymentFee'"
                  :disabled="
                    $user.admin ||
                    $user.role.sales_cheques_edit_item
                      ? false
                      : true
                  "
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>

          <connectionInput
            :title="$t('salesCheques.form.PaymentMethod')"
            :group="$t('salesCheques.form.PaymentMethods')"
            v-model="item.payment_method_id"
            :hasAdd="true"
            :open="'addPaymentMethod'"
            :values="$database.paymentMethods"
            :value="item.payment_fee_id"
            :disabled="
              $user.admin || $user.role.sales_cheques_edit_item
                ? false
                : true
            "
          />
          <checkInput
            :title="$t('salesCheques.form.BankTax')"
            :value="item.bank_tax"
            v-model="item.bank_tax"
            :disabled="
              $user.admin || $user.role.sales_cheques_edit_item
                ? false
                : true
            "
          />
          <monyInput
            :title="$t('salesCheques.form.TaxAmount')"
            v-if="item.bank_tax == 1"
            v-model="item.bank_tax_amount"
            :disabled="
              $user.admin || $user.role.sales_cheques_edit_item
                ? false
                : true
            "
          />
          <div class="col-6"></div>

          <dvider
            :title="$t('salesCheques.form.transferInformation')"
            :description="$t('salesCheques.form.CheckTransferInformation')"
            v-if="item.stat == 2"
          />

          <searchGroupInput
            v-if="item.stat == 2"
            :type="'supplier'"
            :values="$database.suppliers.filter((el) => el.stat == 1)"
            :model="'convert_supplier'"
            :inputs="[
              { title: this.$t('companies.form.supplierName'), show: 'name' },
              { title: this.$t('companies.form.Balance'), show: 'balance', moany: true }
            ]"
          />

          <dvider
            :title="$t('salesCheques.form.PaymentNotes')"
            :description="$t('salesCheques.form.PaymentNotesDetails')"
          />

          <formTextarea
            :title="$t('salesCheques.form.thatAbout')"
            v-model="item.description"
          />
          <formTextarea
            :title="$t('salesCheques.form.Note')"
            v-model="item.notes"
          />
          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'salesChequesCreate' && !$parent.stopEdit"
          >
            {{ $t('salesCheques.form.addSalesPayment') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'salesChequesEdit' && !$parent.stopEdit"
          >
            {{ $t('salesCheques.form.SalesPaymentadjustment') }}
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
    <addPaymentMethod v-if="model == 'addPaymentMethod'"/>
    <addPaymentFee v-if="model == 'addPaymentFee'"/>
  </div>
</template>
<script>
import axios from "axios";

import formInput from "../elements/add/form/formInput.vue";
import formTextarea from "../elements/add/form/formTextarea.vue";
import connectionInput from "../elements/add/form/connectionInput.vue";
import monyInput from "../elements/add/form/monyInput.vue";
import selectInput from "../elements/add/form/selectInput.vue";
import checkInput from "../elements/add/form/checkInput.vue";
import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";
import loading from "../elements/add/loading.vue";
import subFileInput from "../elements/add/form/subFileInput.vue";

import addPaymentMethod from "../elements/popupForms/addPaymentMethod.vue";
import addPaymentFee from "../elements/popupForms/addPaymentFee.vue";

import searchGroupInput from "../elements/add/form/searchGroupInput.vue";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  data() {
    return {
      path: "/salesCheques",
      item: {
        invoice_group: this.$option.sales_cheque_invoice_group_id,
        customer_id: 0,
        supplier_id: 0,
        partner_id: 0,
        project_id: 0,
        project: {},
        project_item_id: 0,
        convert_supplier_id: 0,
        convert_supplier:{
          name: "",
          balance: 0,
        },
        customer: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        supplier: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        partner: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        client_type: 0,
        invoice_id: 0,
        payment_method_id: 0,
        payment_fee_id: [],
        bank_tax: 0,
        safe_id: 0,
        date: "",
        stat: 0,
        created_date: "",
        safe:{},
      },
      selectedPaymentFees: [],
      model: false,
      errors: {
        invoice_group: false,
        customer_id: false,
        cost: false,
        safe_id: false,
      },
      print: 1,
    };
  },
  mounted() {
    this.$updateDatabase([
      "invoiceGroups",
      "customers",
      "suppliers",
      "projects",
      "projectItems",
      "paymentMethods",
      "paymentFees",
    ]);

    if (this.$route.name == "salesChequesEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    }

    if (this.$route.name == "salesChequesCreate") {
      var params = this.$getParams()
      if (params.customer_id) {
        var customer = this.$database.customers.find(
          (el) => el.id == params.customer_id
        );
        this.item.customer_id = params.customer_id;
        this.item.customer = customer;
      }
      
      this.item.created_date = this.$nowDate()
      this.item.date = this.$nowDate(5)
    }

    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
      })
  },
  methods: {
    getItem() {
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.item = response.data;
          this.$parent.stopEdit = false;
          if (Array.isArray(JSON.parse(this.item.payment_fee_id))) {
            JSON.parse(this.item.payment_fee_id).forEach((id) => {
              var paymentFee = this.$database.paymentFees.find((el) => el.id == id);
              this.selectedPaymentFees.push(paymentFee);
            });
          }
          this.item.created_date = this.$dateFormat(this.item.created_date, true)
          this.item.date         = this.$dateFormat(this.item.date, true)
        });
    },
    saveItem() {
      var error = 0;
      if (
        this.item.client_type == 0 &&
        (typeof this.item.customer_id === "undefined" ||
          this.item.customer_id == "")
      ) {
        error = 1;
        this.errors.name = true;
      }
      if (
        this.item.client_type == 1 &&
        (typeof this.item.supplier_id === "undefined" ||
          this.item.supplier_id == "")
      ) {
        error = 1;
        this.errors.name = true;
      }
      if (
        this.item.client_type == 2 &&
        (typeof this.item.partner.name === "undefined" ||
          this.item.partner.name == "") &&
        this.$route.name == "salesChequesCreate"
      ) {
        error = 1;
        this.errors.name = true;
      }
      if (
        typeof this.item.cost === "undefined" ||
        this.item.cost == "" ||
        this.item.cost < 1
      ) {
        error = 1;
        this.errors.cost = true;
      }
      if (
        this.item.stat == 2 &&
        (typeof this.item.convert_supplier_id === "undefined" ||
          this.item.convert_supplier_id == "" ||
          this.item.convert_supplier_id == 0)
      ) {
        error = 1;
        this.errors.convert_supplier_id = true;
      }
      if (
        this.item.stat == 1 &&
        (typeof this.item.safe_id === "undefined" ||
          this.item.safe_id == 0 ||
          this.item.safe_id == "" ||
          this.item.safe_id == null)
      ) {
        error = 1;
        this.errors.safe_id = true;
      }
      if (
        (typeof this.item.invoice_group === "undefined" ||
          this.item.invoice_group == 0 ||
          this.item.invoice_group == "" ||
          this.item.invoice_group == null) &&
        this.$route.name == "salesChequesCreate"
      ) {
        error = 1;
        this.errors.invoice_group = true;
      }
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date)
        this.item.created_date = this.$fixDate(this.item.created_date)
        if (this.$route.name == "salesChequesCreate") {
          this.$parent.aletText = this.$t('allerts.salesChequesuccessfullyAdde');
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));
          if (this.item.client_type == 0) {
            this.item.old_balance = this.item.customer.balance;
          } else {
            this.item.old_balance = this.item.supplier.balance;
          }
          this.item.new_balance = parseFloat(this.item.old_balance) + parseFloat(this.item.cost);
          
          // change customer balance
          if (this.item.client_type == 0) {
            var customer = this.$database.customers.find((el) => el.id == this.item.customer_id);
            if (customer) {
              customer.balance = this.item.new_balance;
            }
          } else {
            var supplier = this.$database.suppliers.find((el) => el.id == this.item.supplier_id);
            if (supplier) {
              supplier.balance = this.item.new_balance;
            }
          }

          // change safe balance
          if (this.item.stat == 1) {
            var safe = this.$database.safes.find((el) => el.id == this.item.safe_id);
            if (safe) {
              safe.balance = parseFloat(safe.balance) + parseFloat(this.item.cost);
            }
          }
          

          localStorage.database = JSON.stringify(this.$database);
          
          this.$router.go(-1);
          this.$parent.stopEdit = false;
        } else if (this.$route.name == "salesChequesEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
                this.$parent.aletText = this.$t('allerts.salesPaymentHasBeenModifiedSuccessfully');
                this.$parent.alertType = "success";
                this.$router.go(-1);
                this.$parent.stopEdit = false;
                return response;
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
  watch: {
    selectedPaymentFees: function (val) {
      this.item.payment_fee_id = val.map((el) => el.id);
    },
  },
  components: {
    formInput,
    dvider,
    addHeader,
    monyInput,
    selectInput,
    formTextarea,
    connectionInput,
    checkInput,
    addPaymentMethod,
    addPaymentFee,
    loading,
    subFileInput,

    searchGroupInput,

    VueCtkDateTimePicker,
  },
};
</script>
